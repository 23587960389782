import React from "react"
import {Twitter, InstagramAlt, Github, LinkedinSquare} from '@styled-icons/boxicons-logos'
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import { Container, Row, Col} from "react-awesome-styled-grid"
import { MariaSecondary, MariaText} from "../utils/colors"

class ContactPage extends React.Component {
    render() {
        const siteTitle = "Contact"
    
        return (
          <Layout location={this.props.location} title={siteTitle}>
            <SEO
              title="Contact"
              keywords={[`video`, `multimedia`, `journalist`, `coder`, `digital content`, `producer`, `developer`,'portfolio']}
              description="Hi! I'm Maria Dermentzi, a Europe-based multimedia journalist with robust coding, data, and video skills."
            />
            <Container>
              <Row>
                <Col xs={4} md={4}>
                <h5>
                  Find me on:
                </h5>
                <SocialUl>
                  <SocialLi><SocialLink href="https://twitter.com/mdermentzi" target="_blank"><Twitter height="2rem" width="2rem" alt="Twitter"/> <span style={{ 
                    height: '1px',
                    width: '1px',
                    position: 'absolute',
                    overflow: 'hidden',
                    top: '-10px'}}>
                      Twitter
                    </span></SocialLink></SocialLi>
                  <SocialLi><SocialLink href="https://www.linkedin.com/in/mdermentzi/" target="_blank"><LinkedinSquare height="2rem" width="2rem" alt="Linkedin"/> <span style={{ 
                    height: '1px',
                    width: '1px',
                    position: 'absolute',
                    overflow: 'hidden',
                    top: '-10px'}}>
                      Linkedin
                    </span></SocialLink></SocialLi>
                  <SocialLi><SocialLink href="https://instagram.com/mdermentzi" target="_blank"><InstagramAlt height="2rem" width="2rem" alt="Instagram"/> <span style={{ 
                    height: '1px',
                    width: '1px',
                    position: 'absolute',
                    overflow: 'hidden',
                    top: '-10px'}}>
                      Instagram
                    </span></SocialLink></SocialLi>
                  <SocialLi><SocialLink href="https://github.com/mdermentzi" target="_blank"><Github height="2rem" width="2rem" alt="Github"/> <span style={{ 
                    height: '1px',
                    width: '1px',
                    position: 'absolute',
                    overflow: 'hidden',
                    top: '-10px'}}>
                      Github
                    </span></SocialLink> </SocialLi>
                </SocialUl>
                </Col>
                <Col xs={4} md={4}>
                  <h5>
                    Or send me an email:
                  </h5>
                  <form name="contact" action="/success" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    
                    <p>
                      <label for="name"> <span style={{ 
                        height: '1px',
                        width: '1px',
                        position: 'absolute',
                        overflow: 'hidden',
                        top: '-10px'}}>Name:
                      </span>
                      <input type="text" name="name" placeholder="Name *" required style={{
                        width: "100%",
                        borderRadius: "10px",
                        color: MariaText,
                        outline: "none",
                        border: "none",
                        padding: "1rem",
                        background: "rgba(250,250,250, 0.25)"
                        }} />  
                      </label>
                    </p>
                    <p>
                      <label for="email"> <span style={{ 
                        height: '1px',
                        width: '1px',
                        position: 'absolute',
                        overflow: 'hidden',
                        top: '-10px'}}>Email:
                          </span>
                        <input type="email" name="email" placeholder="Email *" required style={{
                          width: "100%",
                          borderRadius: "10px",
                          color: MariaText,
                          outline: "none",
                          border: "none",
                          padding: "1rem",
                          background: "rgba(250,250,250, 0.25)"}} />
                      </label>
                    </p>
                    <p>
                      <label for="message"> <span style={{ 
                          height: '1px',
                          width: '1px',
                          position: 'absolute',
                          overflow: 'hidden',
                          top: '-10px'}}>Your message:
                        </span>
                        <textarea name="message" placeholder="Your Message *" required style={{
                          width: "100%",
                          borderRadius: "10px",
                          color: MariaText,
                          resize: "none",
                          outline: "none",
                          border: "none",
                          height: "10rem",
                          padding: "1rem",
                          background: "rgba(250,250,250, 0.25)"}}>
                        </textarea>
                      </label>
                    </p>
                    <p>
                      <Button type="submit">Send</Button>
                    </p>
                  </form>
                </Col>
              </Row>

            </Container>
          </Layout>
        )
      }
}

export default ContactPage

const SocialUl = styled(props => <ul {...props} />)`
    margin-left: 0;
`

const SocialLi = styled(props => <li {...props} />)`
    display: inline-block;
    padding-left: 0rem;
    padding-right: 0.6rem;
    padding-bottom: 0.2rem;
    margin-bottom: 0;
    `

const SocialLink = styled(props => <a {...props} />)`
    position: relative;
    display: inline-block;
    color: ${MariaText};
    &:hover {
      color: ${MariaSecondary};    
    }
  }`